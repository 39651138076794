<div [ngClass]="{'ba-navbar-show': state == true}" class="ba-navbar"
  style="display:inline-block; overflow: auto;  top:65px; width:30%; border-right:1px solid #eee; height:100%; box-shadow:0px 0px 55px rgba(0 0 0 / 10%)">
  <div class="ba-navbar-user text-center user-side-detail">
    <div class="menu-close" (click)="setState()">

      <i class="las la-arrow-left"></i>
    </div>
    <div class="thumb">
      <img src="{{global.user_profile.profile_image}}" alt="user" width="100px" height="100px"
        style="border-radius: 50%; background-image: linear-gradient(0deg, #02a1fb 0%, #7a00ff 100%);">
    </div>
    <div class="details">
      <div style="margin: 0px 0px 15px 0px;">
        <h5>{{global.user_global.employee_name}}</h5>
        <p>ID: {{global.user_global.employee_id}}</p>
      </div>
      <div style="display: flex;align-items: center;flex-wrap: nowrap;flex-direction: column;">
        <span class="tag styleit">{{global.user_global.user_role}} - {{global.user_global.company}}</span>
        <span class="tag styleit">Report to: <b>{{global.user_global.reports_to_name}}</b></span>
      </div>
    </div>
  </div>
  <div class="ba-balance-inner mb-3 text-end align-self-center none">
    <div class="icon">
      <img src="./assets/img/1.png" alt="img">
    </div>
    <h5 class="title">My Claim</h5>
    <h5 class="amount">RM56,985.00</h5>
  </div>

  <div class="ba-main-menu">
    <h5>Menu</h5>
    <ul>
      <li>
        <img src="./assets/img/icon/dashboard.png" alt="user">
        <a href="/" [ngClass]="{'active': activeMenu === 'dashboard'}" style="cursor: pointer;">Dashboard</a>
      </li>
      <li>
        <img src="./assets/img/icon/user_setting.png" alt="user">
        <a href="/profile" class="mt-2" [ngClass]="{'active': activeMenu === 'profile'}"
          style="cursor: pointer;">Profile</a>
      </li>
      <li>
        <img src="./assets/img/icon/listing.png" alt="user">
        <a [ngClass]="{'active': activeMenu === 'listing'}" class="mt-2 modules" style="cursor: pointer;"
          (click)="openListing()">Listing</a>
      </li>
      <ul *ngIf="moduleList" class="sidemenu_list_parent" style="margin-left: 25px; margin-bottom: 0px !important;">
        <li><a [ngClass]="{'active-child': activeChildMenu === 'eclaim'}" href="/eclaim/listing"><b>E-Claim</b></a></li>
        <li><a [ngClass]="{'active-child': activeChildMenu === 'eleave'}" href="/eleave/listing"><b>E-Leave</b></a></li>
        <li *ngIf="global.user_global.is_hr"><a [ngClass]="{'active-child': activeChildMenu === 'hremployeeleave'}"
            href="/eleave/hr-employee-leave-list"><b>HR-Employee-Leave</b></a></li>
      </ul>
      <li>
        <img src="./assets/img/icon/approval.png" alt="user">
        <a [ngClass]="{'active': activeMenu === 'approval'}" class="mt-2 modules" style="cursor: pointer;"
          (click)="openApproval()" style="cursor: pointer;">Approval</a>
      </li>
      <ul (click)="onClick($event)" *ngIf="moduleApproval" class="sidemenu_list_parent"
        style="margin-left: 25px; margin-bottom: 0px !important;">
        <li class="mt-2"><a href="/eclaim/approval"
            [ngClass]="{'active-child': activeChildMenu === 'eclaim-approval'}"><b>E-Claim</b></a></li>
        <li class="mt-2"><a href="/eleave/approval"
            [ngClass]="{'active-child': activeChildMenu === 'eleave-approval'}"><b>E-Leave</b></a></li>
      </ul>
      <li>
        <img src="./assets/img/icon/logout.png" alt="user">
        <a class="mt-6 text-pending" style="cursor: pointer;" (click)="logout()">Logout</a>
      </li>
    </ul>
  </div>
</div>